@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
    overflow-x: hidden;
}

ul{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
}

a {
    text-decoration: none;
}

.ActiveNavButton {
    border: transparent !important;
    background: rgba(255, 255, 255, 0.2) !important;
}

.ActiveNavButton:hover {
    background: rgba(103, 103, 103, 0.2) !important;
}

/* Home Page portals zoom affect */
.zoom-parent .zoom-image {
    transform: scale(1.05);
    transition: transform 1s ease-in-out;
}

.map{
    overflow: hidden;
    display: block;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (max-width: 1450px) {
        width: 400px;
        height: 400px;
    }
    @media (max-width: 1250px) {
        width: 300px;
        height: 300px;
    }
    @media (max-width: 1100px) {
        width: 400px;
        height: 400px;
    }
    @media (max-width: 500px) {
        width: 300px;
        height: 300px;
    }
}


.carousel-container {
    position: relative; /* Make the container relative for dot positioning */
    max-width: 25vw; /* Matches the Img styling width */
    margin: 0 auto;
    @media (max-width: 500px) {
        max-width: 80vw;
    }
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 25vw; /* Matches Img width */
    position: relative; /* Ensure dot alignment inside each item */

    @media (max-width: 500px) {
        max-width: 80vw;
    }
}

.react-multi-carousel-dot-list {
    position: absolute;
    /*bottom: -40%; !* Position dots consistently 10px from the bottom of the carousel *!*/
    left: 50%;
    transform: translateY(-20%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 5; /* Ensure dots appear above other content */

}

.react-multi-carousel-dot button {
    background: rgb(69, 69, 69); /* Dot color */
    border-radius: 50%;
    border: none;
    margin: 0 5px;
    width: 10px !important;
    height: 10px !important;
}


/* Inside Folder Page Carousel Styles*/
.bridal-folder-carousel {
    max-width: 30vw; /* Expands the carousel container */
    margin: 0; /* Centers the carousel */
    position: relative; /* Keeps dots and arrows properly aligned */
    flex: 1; /* Allow the carousel to expand within the flex container */
    width: 100%;
    display: block; /* Override potential flexbox conflicts in carousel styles */

    @media (max-width: 1200px) {
        max-width: 80vw;
    }
    @media (max-width: 600px) {
        max-width: 80vw;
    }
}

.bridal-folder-carousel-item {
    justify-content: center;
    align-items: center;
    max-width: 30vw; /* Matches the carousel width */
    display: block; /* Ensure items stack properly */
    width: 100%;

    @media (max-width: 1200px) {
        max-width: 80vw;
    }
    @media (max-width: 500px) {
        max-width: 80vw;
    }
}










